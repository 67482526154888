.body {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('assets/hame_background.webp');
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
/*.body::before   {*/
/*  content: "";*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  background-color: rgba(0, 0, 0, 0.8);*/
/*  z-index: -1;*/
/*}*/

.container {
  text-align: center;
  display: flow;
  background-size: cover;
}

.header,.about, .join, .contact {
  z-index: 2;
  position: relative;
}

.header, .about, .join, .contact {
  /*background-color: #0d0d0d;*/
  color: #00ffcc;
  padding: 40px 20px;
  text-align: center;
}

.header h2, .about h2, .join h2, .contact h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 2px solid #00ffcc;
  display: inline-block;
  text-shadow: 0 0 10px #00ffcc, 0 0 20px #00cc99;
}

.header p,.about p, .join p, .contact p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  text-shadow: 0 0 10px #00ffcc;
  z-index: 2;
  position: relative;
}

.cta-button {
  background-color: #00ffcc;
  color: #000;
  padding: 15px 30px;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: background-color 0.3s;
  box-shadow: 0 0 10px #00ffcc, 0 0 20px #00ffcc, 0 0 30px #00cc99;
}

.cta-button:hover {
  background-color: #00cc99;
  box-shadow: 0 0 20px #00cc99, 0 0 30px #00cc99, 0 0 40px #009966;
}

form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input, textarea {
  background-color: #1a1a1a;
  border: 2px solid #00ffcc;
  color: #00ffcc;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  transition: border-color 0.3s;
  box-shadow: 0 0 10px #00ffcc, 0 0 20px #00cc99;
}

input:focus, textarea:focus {
  border-color: #00cc99;
  outline: none;
  box-shadow: 0 0 20px #00cc99, 0 0 30px #00ffcc;
}

button {
  background-color: #00ffcc;
  color: #000;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 0 10px #00ffcc, 0 0 20px #00cc99;
}

button:hover {
  background-color: #00cc99;
  box-shadow: 0 0 20px #00cc99, 0 0 30px #009966;
}
